import { keyframes, styled } from 'styled-components';
import backgroundImage from '../../assets/img/background.png';

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const LayoutContainer = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  min-height: auto; 
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: -1;
  }
`;

export const Banner = styled.div`
  background: #0099cc;
  color: white;
  padding: 3rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center

  img {
    width: 200px;
    height: auto;
    margin-right: 20px;
    align-self: flex-start;
  }

  div {
    flex-grow: 1;
    text-align: center;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    animation: ${float} 2s ease-in-out infinite;
  }

  /** ✅ SOLO SE MODIFICA EN MÓVILES */
  @media (max-width: 768px) {
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      flex-direction: column;
      align-items: center;
    }

    img { 
      display: none; 
    }
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff; 
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1.7rem;
  }

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  color: #ffffff;
  margin-bottom: 1rem;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

export const Button = styled.button`
  background: linear-gradient(135deg, #007bff, #00bfff);
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 1rem;
  transition: all 0.3s;

  &:hover {
    background: linear-gradient(135deg, #0056b3, #007bbf);
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    font-size: 1.1rem;
    padding: 0.7rem 1.8rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 0.5rem 1.2rem;
    border-radius: 12px; 
  }
`;
