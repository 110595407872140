import styled, { keyframes } from 'styled-components';

// Animaciones para la página About
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Contenedor principal para la página About
export const AboutContainer = styled.div`
  padding: 2rem;
  text-align: center;
  color: #003366;
  animation: ${fadeIn} 1.5s ease-in-out;
`;


// Sección de historia de la empresa
export const Section = styled.div`
  margin: 2rem 0;
  padding: 2rem;
  background: #e3f2fd;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: left;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #004d80;
    margin-bottom: 1rem;
  }
`;

// Sección del equipo
export const TeamContainer = styled.div`
  margin: 3rem 0;
`;

export const TeamTitle = styled.h3`
  font-size: 2.5rem;
  color: #003366;
  margin-bottom: 2rem;
`;

export const TeamGrid = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const TeamMemberCard = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 240px;
  text-align: center;
  animation: ${fadeIn} 1.5s ease-in-out;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    animation: ${float} 2s ease-in-out infinite; /* Movimiento solo al hacer hover */
  }

  img {
    width: 100px;
    height: auto;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.5rem;
    color: #003366;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    color: #004d80;
  }
`;

// Sección de Misión y Visión
export const MissionVisionSection = styled.div`
  margin: 3rem 0;
  background: #b3e5fc;
  padding: 3rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: left;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #004d80;
    margin-bottom: 1rem;
  }
`;

// Estilo para las citas de Misión y Visión
export const MissionVisionQuote = styled.blockquote`
  font-style: italic;
  font-size: 1.2rem;
  color: #003366;
  border-left: 4px solid #0099cc;
  padding-left: 1rem;
  margin: 1rem 0;
`;

// Objetivos de la empresa
export const ObjectivesSection = styled.div`
  margin: 3rem 0;
  background: #ffffff;
  padding: 3rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: left;

  h3 {
    font-size: 2.5rem;
    color: #003366;
    margin-bottom: 1.5rem;
  }

  ul {
    list-style: disc;
    padding-left: 2rem;

    li {
      font-size: 1.2rem;
      color: #004d80;
      margin-bottom: 0.5rem;
    }
  }
`;
