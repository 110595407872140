import styled, { keyframes } from 'styled-components';

// Animaciones para la página de Contacto
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Contenedor principal para la página Contact
export const ContactContainer = styled.div`
  background: linear-gradient(180deg, #b3e5fc, #e0f7fa);
  padding: 2rem;
  text-align: center;
  color: #003366;
  animation: ${fadeIn} 1.5s ease-in-out;
`;

// Wrapper del formulario de contacto
export const FormWrapper = styled.div`
  background: #ffffff;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto 3rem auto;  /* Centra el FormWrapper */
  text-align: center;  /* Centraliza el contenido */
  animation: ${fadeIn} 1.5s ease-in-out;
`;

// Campo de Input
export const InputField = styled.input`
  width: 90%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  border: 1px solid #0099cc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #004d80;
  }
`;

// Área de Texto
export const TextArea = styled.textarea`
  width: 90%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  border: 1px solid #0099cc;
  border-radius: 8px;
  outline: none;
  resize: vertical;
  transition: border-color 0.3s;

  &:focus {
    border-color: #004d80;
  }
`;

// Campo de selección (Select)
export const SelectField = styled.select`
  width: 90%;
  padding: 1rem;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  border: 1px solid #0099cc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: #004d80;
  }
`;

// Input para adjuntar archivos
export const AttachmentInput = styled.input`
  margin-bottom: 1.5rem;
  font-size: 1rem;
`;

// Contenedor de información de contacto
export const ContactInfoContainer = styled.div`
  margin: 2rem auto;  /* Ajustado para centralizar el contenedor */
  padding: 2rem;
  background: #e3f2fd;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  text-align: center;

  h3 {
    font-size: 2rem;
    color: #003366;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: #004d80;
    margin-bottom: 0.5rem;
  }
`;

// Caja de información (Contact Info Box)
export const InfoBox = styled.div`
  text-align: center;
`;
