import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import api from '../../Services/api';
import EmailInput from '../../Components/CustomFields/EmailInput/EmailInput';
import PasswordInput from '../../Components/CustomFields/PasswordInput/PasswordInput';
import MainLayout from '../Layout/MainLayout';
import { Button } from '../Layout/style';

const Login: React.FC = () => {
    const [usuario, setUsuario] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        setError('');

        try {
            const response = await api.post('/api/auth/login', { usuario, password });
            if (response.status === 200) {
                const { token } = response.data;
                localStorage.setItem('token', token);
                navigate('/');
            }
        } catch (error) {
            setError('Email o contraseña incorrectos');
        }
    };

    return (
        <MainLayout>
            <Container maxWidth="xs">
                <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                    <img src="/img/giftwave_logo.png" alt="GiftWave Logo" style={{ width: 150, marginBottom: 20 }} />
                    <Typography variant="h5" component="h1" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 600 }}>
                        Bienvenido
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 500, color: '#666' }}>
                        Iniciar sesión
                    </Typography>

                    <form onSubmit={handleLogin} style={{ width: '100%', gap: '16px', display: 'flex', flexDirection: 'column' }}>
                        <EmailInput
                            label="Email"
                            value={usuario}
                            onChange={(e) => setUsuario(e.target.value)}
                            required
                        />
                        <PasswordInput
                            label="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        {error && <Typography color="error">{error}</Typography>}
                        <Button>
                            Entrar
                        </Button>
                    </form>
                </Box>

                <Box mt={2}>
                    <Typography variant="body2" align="center" sx={{ fontFamily: 'Montserrat', color: '#003366' }}>
                        ¿Olvidaste la contraseña? <a href="/forgot-password" style={{ color: '#003366', textDecoration: 'none', fontWeight: 'bold' }}>Recuperar</a>
                    </Typography>
                    <Typography variant="body2" align="center" sx={{ fontFamily: 'Montserrat', color: '#003366', mt: 1 }}>
                        ¿Aún no estás registrado? <a href="/register" style={{ color: '#003366', textDecoration: 'none', fontWeight: 'bold' }}>Regístrate</a>
                    </Typography>
                </Box>

            </Container>
        </MainLayout>
    );
};

export default Login;
