import React, { useEffect, useState } from 'react';
import {
    GiftCardsContainer,
    CategoryFilter,
    CategoryItem,
    GiftCardsGrid,
    GiftCardItem,
    GiftCardImage,
    GiftCardTitle,
    GiftCardDescription,
    GiftCardPrice,
} from './style';
import GiftCardModal from './GiftCardModal';
import MainLayout from '../Layout/MainLayout';
import { GiftCard } from '../../Interfaces/formInterfaces';
import { useGiftCards, useCategorias } from '../../Hooks/useConfigData';
import { Banner, Button, Subtitle, Title } from '../Layout/style';

const GiftCards: React.FC = () => {
    const { giftCards } = useGiftCards();
    const { categorias } = useCategorias();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [filteredGiftCards, setFilteredGiftCards] = useState<GiftCard[]>([]);
    const [selectedGiftCard, setSelectedGiftCard] = useState<GiftCard | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (selectedCategories.length > 0) {
            setFilteredGiftCards(
                giftCards.filter(giftCard => selectedCategories.includes(giftCard.categoria))
            );
        } else {
            setFilteredGiftCards(giftCards);
        }
    }, [giftCards, selectedCategories]);

    const handleCategoryClick = (category: string) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(cat => cat !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const handleGiftCardClick = (giftCard: GiftCard) => {
        setSelectedGiftCard(giftCard);
        setIsModalOpen(true);
    };

    return (
        <MainLayout>
            <GiftCardsContainer>
                <Banner>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/img/giftwave_logo.png"
                            alt="GiftWave Logo"
                            style={{ width: '180px', height: 'auto', marginRight: '20px' }}
                        />
                        <div>
                            <Title>GiftCards y Experiencias !!!</Title>
                            <Subtitle>Las mejores gift cards para todos los gustos</Subtitle>
                        </div>
                    </div>
                </Banner>

                <CategoryFilter>
                    {categorias.map(category => (
                        <CategoryItem
                            key={category.id}
                            selected={selectedCategories.includes(category.nombre)}
                            onClick={() => handleCategoryClick(category.nombre)}
                        >
                            {category.nombre}
                        </CategoryItem>
                    ))}
                </CategoryFilter>

                <GiftCardsGrid>
                    {filteredGiftCards.map(giftCard => (
                        <GiftCardItem key={giftCard.id}>
                            <GiftCardImage src={giftCard.imagenUrl} alt={giftCard.nombre} />
                            <GiftCardTitle>{giftCard.nombre}</GiftCardTitle>
                            <GiftCardPrice>Precio: ${giftCard.valor}</GiftCardPrice>
                            <GiftCardDescription>
                                {giftCard.descripcion.length > 80
                                    ? `${giftCard.descripcion.substring(0, 80)}...`
                                    : giftCard.descripcion}
                            </GiftCardDescription>
                            <Button onClick={() => handleGiftCardClick(giftCard)}>
                                Ver Detalles
                            </Button>
                        </GiftCardItem>
                    ))}
                </GiftCardsGrid>

                {isModalOpen && selectedGiftCard && (
                    <GiftCardModal
                        giftCard={selectedGiftCard}
                        onClose={() => setIsModalOpen(false)}
                    />
                )}
            </GiftCardsContainer>
        </MainLayout>
    );
};

export default GiftCards;
